/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";

import { Link } from "react-router-dom";

import pt from "../../assets/img/about-us/ab-us.jpg";
import about from "../../assets/img/services/services-1.jpg";
import services from "../../assets/img/country-img/io.jpg";
import "./table.scss";
import s1 from "../../assets/img/services/s-1.jpg";
import s2 from "../../assets/img/services/s-2.jpg";
import s3 from "../../assets/img/services/s-3.jpg";
import s4 from "../../assets/img/services/s-4.jpg";
import s5 from "../../assets/img/services/s-5.jpg";
import s6 from "../../assets/img/services/s-6.jpg";
import s7 from "../../assets/img/services/s-7.jpg";
import s8 from "../../assets/img/services/s-8.jpg";
import service16 from "../../assets/img/services/service-16.jpg";

import pdf from "../../assets/pdf/TRAVELKOOK-INVESTMENT-OPTIONS.pdf";

// import tabs from "./Tabs";
import Tabs from "./Tabs";

const Main = () => {
  // Function will execute on click of button
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(pdf).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = pdf;
        alink.click();
      });
    });
  };

  return (
    <>
      {/* header area end here */}
      {/* page title area start */}
      <div
        className="page-title__area pt-110"
        style={{ backgroundImage: "url(" + pt + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="page__title-wrapper text-center">
                <h3 className="pb-100">Services</h3>
              </div>
            </div>
          </div>
        </div>
        <nav className="breadccrumb-bg">
          <ul className="breadcrumb justify-content-center pt-20 pb-20">
            <li className="bd-items">
              <Link to="/">Home</Link>
            </li>
            <li className="bd-items bdritems">|</li>
            <li className="bd-items">
              <Link to="/services">Services</Link>
            </li>
          </ul>
        </nav>
      </div>
      {/* page title area end */}
      {/* services featurs start */}
      <div className="services-featurs pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="services-fimg">
                <img src={about} alt="" />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="row">
                <div className="section_title_wrapper pt-40">
                  <span className="subtitle">Featured Services</span>
                  <h2 className="section-title">EDUCATIONAL CONSULTING</h2>
                  <p className="pt-30 pb-10 mr-25">
                    Travelkook now is partnered with over 200 universities in
                    the world and provides education consultancy packages mainly
                    in
                  </p>
                  <div className="check-use mb-10">
                    <Link to="/australia">
                      <i className="far fa-check-square" /> Australia
                    </Link>
                    <a href="/germany">
                      <i className="far fa-check-square" /> Germany
                    </a>
                    <a href="/usa">
                      <i className="far fa-check-square" /> USA
                    </a>
                    <a href="/canada">
                      <i className="far fa-check-square" /> Canada
                    </a>
                    <a href="/poland">
                      <i className="far fa-check-square" /> Poland
                    </a>
                  </div>
                  <p className="pt-10 pb-10">
                    We provide admission assistance to majority countries in
                    Europe like UK, France, Spain, Malta, Romania, Hungary etc.
                  </p>
                  <p className="pt-10 pb-10">Our Packages Consists Of:</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* services featurs end */}
      {/* featurs area start here */}
      <section className="featurs-services pt-90 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="features shadow-sm p-3">
                <div className="features__thumb">
                  <a href="business-visa.html">
                    <img src={s1} alt="" style={{ height: "190px" }} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="features shadow-sm p-3">
                <div className="features__thumb">
                  <a href="business-visa.html">
                    <img src={s2} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="features shadow-sm p-3">
                <div className="features__thumb">
                  <a href="business-visa.html">
                    <img src={s3} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="features shadow-sm p-3">
                <div className="features__thumb">
                  <a href="business-visa.html">
                    <img src={s4} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="features shadow-sm p-3">
                <div className="features__thumb">
                  <a href="business-visa.html">
                    <img src={s5} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="features shadow-sm p-3">
                <div className="features__thumb">
                  <a href="business-visa.html">
                    <img src={s6} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="features shadow-sm p-3">
                <div className="features__thumb">
                  <a href="business-visa.html">
                    <img src={s7} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="features shadow-sm p-3">
                <div className="features__thumb">
                  <a href="business-visa.html">
                    <img src={s8} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <p className=" ">
            So if you are looking for a complete educational consulting package,
            Travelkook is your one stop solution for it.
          </p>
        </div>
      </section>

      {/* featurs area end here */}
      {/* Ab-fact-area start */}
      <div
        className="abfact-area services-vrly pt-85 pb-285"
        style={{ backgroundImage: "url(" + services + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="fact fact-2 abfact-items text-center">
                <h1 className="counter-count">
                  <span className="counter">25</span>k+
                </h1>
                <span>Happy Clients &amp; Students</span>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="fact fact-2 abfact-items text-center ">
                <h1 className="counter-count">
                  <span className="counter">20</span>+
                </h1>
                <span>Countries Affiliation</span>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="fact fact-2 abfact-items text-center ">
                <h1 className="counter">
                  <span className="counter">200</span>+
                </h1>
                <span>Top University Partner</span>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
              <div className="fact fact-2 abfact-items text-center ">
                <h1 className="counter-count">
                  <span className="counter">10</span>k+
                </h1>
                <span>Visa &amp; Immigration</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Ab-fact-area end */}
      {/* intro-area start */}
      <section className="intro-area pb-80">
        <div className="container">
          <div
            className="row service-intro-top g-0"
            style={{ border: "0.5px solid #bcbcbc" }}
          >
            <div className="col-xxl-12 col-xl-12 col-lg-12 d-flex align-items-center text-center">
              <div className="section_title_wrapper pl-50 pr-70">
                <span className="subtitle p-4"></span>
                <h2
                  className="section-title subtitle"
                  style={{ color: "#1A1C20", fontSize: "40px" }}
                >
                  RESIDENCY OR INVESTMENT BY CITIZENSHIP
                </h2>
                <p className="pt-30 pb-25 ">
                  Travelkook offers residency and citizenship programs through
                  investment through which people can immigrate to a new country
                  with their family. This allows foreign individuals to obtain
                  citizenship or temporary or permanent residence rights on the
                  basis of local investments.
                </p>
                <p className="pt-0 pb-10 ">
                  Travelkook offers Residency by Investment / Citizenship by
                  Investment to the following countries:{" "}
                </p>

                <span className="">
                  {" "}
                  For more details contact us at <br />
                  <span
                    className="subtitle"
                    style={{ color: "#002971", fontSize: "20px" }}
                  >
                    info@travelkook.com
                  </span>
                </span>
                {/* <div className="check-use mb-40">
                  <a href="business-visa.html">
                    <i className="far fa-check-square" /> Visa Requests
                  </a>
                  <a href="business-visa.html">
                    <i className="far fa-check-square" /> Visa Apply
                  </a>
                  <a href="business-visa.html">
                    <i className="far fa-check-square" /> Visa Service
                  </a>
                </div>
                <div className="abinfro-btn d-flex align-items-center">
                  <a href="services.html" className="theme-btn">
                    See Packages
                  </a>
                </div> */}
              </div>
            </div>
            {/* <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="intro-right">
                <img src={aboutus} alt="" />
                <div className="intro-btn">
                  <a
                    className="play-btn popup-video"
                    href="https://www.youtube.com/watch?v=pNje3bWz7V8"
                  >
                    <i className="flaticon-play-button" />
                  </a>
                </div>
              </div>
            </div> */}

            <Tabs />
          </div>
        </div>
      </section>
      {/* intro-area end */}
      {/* Ab-fact-area start */}
      <div
        className="abfact-area services-vrly pt-85 pb-285"
        style={{ backgroundImage: "url(" + services + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2"></div>

            <div className="d-flex col-xxl-8 col-xl-8 col-lg-8 col-md-8">
              <span className="d-flex align-items-center mr-30">
                <span className="d-flex docu__thumb mr-15">
                  <a>
                    <i className="fas fa-file-pdf"></i>
                  </a>
                </span>
                <span className="d-flex docu__text">
                  <h2>
                    <a className="text-white">TRAVELKOOK INVESTMENT OPTIONS</a>
                  </h2>
                </span>
              </span>
              <span className="d-flex align-items-center">
                <button class="theme-btn " onClick={onButtonClick}>
                  {" "}
                  Download Now
                </button>
              </span>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2"></div>
          </div>
        </div>
      </div>
      {/* Ab-fact-area end */}
      {/* prices-area start */}
      <section className="intro-area pricing pb-60">
        <div className="container">
          <div className="row service-intro-top">
            <div className="col-xxl-7 col-xl-7 col-lg-7 pl-70">
              <div className="container pt-25 mb-25 mt-25">
                <span className="subtitle">Our Exclusive</span>
                <h2 className="section-title">PRICES</h2>
                <h2
                  className="section-title subtitle"
                  style={{ color: "#1A1C20", fontSize: "40px" }}
                ></h2>

                <ul className="responsive-table">
                  <li className="table-header">
                    <div className="col col-1">COUNTRIES</div>
                    <div className="col col-2">PRICES</div>
                  </li>
                  <li className="table-row shadow-sm">
                    <div className="col col-1" data-label="Job Id">
                      USA
                    </div>
                    <div className="col col-2" data-label="Customer Name">
                      USD 20,000 TO 50,000 PER FAMILY
                    </div>
                  </li>
                  <li className="table-row shadow-sm">
                    <div className="col col-1" data-label="Job Id">
                      AUSTRALIA
                    </div>
                    <div className="col col-2" data-label="Customer Name"></div>
                  </li>
                  <li className="table-row shadow-sm">
                    <div className="col col-1" data-label="Job Id">
                      PORTUGAL
                    </div>
                    <div className="col col-2" data-label="Customer Name">
                      30,000 EUROS PER FAMILY
                    </div>
                  </li>
                  <li className="table-row shadow-sm">
                    <div className="col col-1" data-label="Job Id">
                      MALTA
                    </div>
                    <div className="col col-2" data-label="Customer Name">
                      10,000 EUROS PER PERSON
                    </div>
                  </li>
                  <li className="table-row shadow-sm">
                    <div className="col col-1" data-label="Job Id">
                      CYPRUS
                    </div>
                    <div className="col col-2" data-label="Customer Name">
                      30,000 EUROS OR MORE
                    </div>
                  </li>
                  <li className="table-row shadow-sm">
                    <div className="col col-1" data-label="Job Id">
                      SPAIN
                    </div>
                    <div className="col col-2" data-label="Customer Name">
                      1000 EUROS FOR MAIN APPLICATION 600 EUROS FOR EACH
                      DEPENDENT 2% OF THE PROPERTY PRICE FOR PROPERTY SEARCH
                    </div>
                  </li>
                  <li className="table-row shadow-sm">
                    <div className="col col-1" data-label="Job Id">
                      GREECE
                    </div>
                    <div className="col col-2" data-label="Customer Name">
                      30,000 EUROS OR MORE
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-lg-5">
              <div className="section_title_wrapper pl-70 pr-70 pt-80 mt-70">
                <img src={service16} alt="" />
                <span className="subtitle p-4"></span>
                <h4 className="subtitle">
                  FOR ALL CASES CONSULTANCY PLUS LEGAL FEES MAY PRICE UPTO 4 TO
                  7 PERCENT OF THE PROPERTY PRICE
                </h4>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* prices-area end */}
    </>
  );
};

export default Main;
