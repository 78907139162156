import React from "react";

import { Link } from "react-router-dom";

import pt from "../../assets/img/contact/contact-banner.png";

import img_one from "../../assets/img/contact/img_one.png";

const Main = () => {
  return (
    <div>
      {/* header area end here */}
      {/* page title area start */}
      <div
        className="page-title__area pt-110"
        style={{ backgroundImage: "url(" + pt + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="page__title-wrapper text-center">
                <h3 className="pb-100">Contact Us</h3>
              </div>
            </div>
          </div>
        </div>
        <nav className="breadccrumb-bg">
          <ul className="breadcrumb justify-content-center pt-20 pb-20">
            <li className="bd-items">
              <Link to="/">Home</Link>
            </li>
            <li className="bd-items bdritems">|</li>
            <li className="bd-items">
              {" "}
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </nav>
      </div>
      {/* page title area end */}
      <main>
        {/* Contact  area start */}
        <div className="contact__area">
          <div className="contact__vmap"></div>
          <div className="container">
            <div className="row">
              <div className="col-xxl-7 col-xl-7 col-lg-7 pt-5">
                <div className="row">
                  <div className="testimonail__wrapper__info__img mt-5 pt-5 ">
                    <img src={img_one} alt="" className="pt-5 mt-5" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 pt-4 text-center">
                    <h6>Contact No: +965-98726180</h6>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 pt-4 text-center">
                    <h6>Email: info@travelkook.com</h6>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-xl-5 col-lg-5">
                <div className="contact__form pt-110">
                  <h2 className="contact-form__title">
                    Get in <span>Touch </span>
                  </h2>
                  <form
                    id="contact-form"
                    action="assets/mail.php"
                    method="POST"
                  >
                    <div className="row">
                      <div className="col-xl-6">
                        <input
                          name="name"
                          className="contact__input"
                          type="text"
                          placeholder="Your Name"
                        />
                      </div>
                      <div className="col-xl-6">
                        <input
                          name="email"
                          className="contact__input"
                          type="email"
                          placeholder="Your Email"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6">
                        <input
                          name="phone"
                          className="contact__input"
                          type="text"
                          placeholder="Phone"
                        />
                      </div>
                      <div className="col-xl-6">
                        <input
                          name="subject"
                          className="contact__input"
                          type="text"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <textarea
                          name="message"
                          className="contact__input txt-area "
                          cols={30}
                          rows={10}
                          placeholder="Message"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <button className="theme-btn" type="button">
                          Submit Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact  area end */}
        {/* abbrand-area start */}
        <div
          className="abbrand-area pb-120 wow fadeInUp"
          data-wow-delay="0.3s"
          style={{
            visibility: "visible",
            animationDelay: "0.3s",
            animationName: "fadeInUp",
          }}
        ></div>
        {/* abbrand-area end */}
      </main>
    </div>
  );
};

export default Main;
