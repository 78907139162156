import React from "react";

import { Link } from "react-router-dom";
import visabnr from "../../../assets/img/visa/v-1.jpg";

const Main = () => {
  return (
    <main>
      <div>
        <div
          className="page-title__area pt-110"
          style={{ backgroundImage: "url(" + visabnr + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">Work & Job Visa</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items">
                <Link to="/">Home</Link>
              </li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items">Work & Job Visa</li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <section
          className="blog-area pt-60 pb-30 wow fadeInUp"
          data-wow-delay="0.3s"
          style={{
            visibility: "visible",
            animationDelay: "0.3s",
            animationName: "fadeInUp",
          }}
        >
          <div className="container">
            <div className="row">
              <p>
                All nationals who intend to work outside their home country need
                a work visa to work in a foreign country.
              </p>

              <p className="pt-15 pb-0">
                <h6>PROCEDURE FOR OBTAINING WORK PERMIT:</h6>
              </p>
              <ul className="pl-50">
                {" "}
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Submit your CV, Passport Copy, Photo and Degree certificates
                    and Transcripts
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Apply for Specific Job</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Attend Online Interview</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Once you are selected, your employer will submit the work
                    permit for you
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Once you receive the work permit, you can apply for your
                    work visa from your country of residence or you home
                    country.
                  </p>
                </li>
              </ul>
              <p className="pt-15 pb-0">
                <h6>DOCUMENTS REQUIRED FOR APPLYING FOR WORK VISA:</h6>
              </p>
              <ul className="pl-50">
                {" "}
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Passport – valid atleast for 6 months from the date of
                    application
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    National ID or Card or Residency Card if applying from other
                    country other than home country - valid atleast for 3 months
                    from the date of application
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Photo (size and specifications varies from country to
                    country)
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Degree Certificates and Transcripts</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">CV</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Bank Statement</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Salary Certificate</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Other proof of employments</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    1 year Travel Health Insurance varies from country to
                    country
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Police Clearance – mandatory for some countries
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Medical Certificate – required for some countries
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">
                    Work Appointment Proof like – work permit documents from the
                    employer, appointment letter, contract of mandate, For
                    Canada – LMIA is compulsory.
                  </p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Proof of Accommodation</p>
                </li>
                <li style={{ listStyle: "auto", marginLeft: "15px" }}>
                  <p className="mb-0">Employer's letter to the consulate</p>
                </li>
              </ul>
              <p className="pt-15 pb-0">
                For further enquires please contact <b>info@travelkook.com</b>
                or whatsapp at <b>+965-98726180</b>.
              </p>
            </div>
          </div>
        </section>

        {/* JS here */}
      </div>
    </main>
  );
};

export default Main;
